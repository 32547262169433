// const React = require("react")
import React, { useContext } from "react"
import ModeContext from "../context/ModeContext"
import SettingsContext from "../context/SettingsContext"
const Sketch = typeof window !== `undefined` ? require("react-p5") : null

let particles = []
const Particles = ({ color }) => {
  const { dark } = useContext(ModeContext)
  const { settings, getSettings } = useContext(SettingsContext)

  const vel = settings.dotVelocity * 10
  // const vel = .4


  let setup = (p5, canvasParentRef) => {
    p5.createCanvas(window.innerWidth, window.innerHeight).parent(
      canvasParentRef
    )
    // p5.createCanvas(260, 260).parent(
    //   canvasParentRef
    // )

    // const particlesLength = Math.floor(window.innerWidth / 45);

    // const particlesLength = window.innerWidth < 450 ? 8 : 10
    // const particlesLength = 1
    // const particlesLength = 15
    const particlesLength = 10
    if (particles.length === 0) {
      for (let i = 0; i < particlesLength; i++) {
        particles.push(new Particle(p5))
      }
    }
  }

  let draw = p5 => {

    p5.clear()

    particles.forEach((p, index) => {
      p.update(p5)
      p.draw(p5, dark)
      p.checkParticles(particles.slice(index), p5, dark)
    })

  }

  // const defaultColor = !dark ? "rgba(0,0,0,.75)" : "rgba(51,255,51,.75)"
  const colors = ["rgba(0, 255, 0, .75)", "rgba(0, 255, 0, .75)", "rgba(0, 255, 0, .75)", "rgba(255, 0, 0, .75)", "rgba(255, 0, 0, .75)", "rgba(0, 0, 255, .75)", "rgba(41, 41, 41, .5)", "rgba(255, 255, 255, .75)", "rgba(30, 139, 195, .75)", "rgba(30, 139, 195, .75)"]
  // const colors = [ "rgba(0,0,0,.25)", "rgba(0,0,0,.25)", "rgba(255, 0, 0, .5)", "rgba(255, 0, 0, .5)", "rgba(0,255,0,.5)", "rgba(0,255,0,.5)", "rgba(99,89,158, .5)", "rgba(245, 230, 83, .5)", "rgba(255,255,255,1", "rgba(0,0,255,.5)"]
  // const colors = [ "rgba(255,255,255,.75)", ]
  // const colors = ['rgba(30, 139, 195, .75)']
  // const colors = [ "rgba(0,0,0,.25)", ]
  // const colors = [color]

  class Particle {
    constructor(p5) {
      // Position
      this.pos = p5.createVector(p5.random(50, p5.width - 50), p5.random(50, p5.height - 50))
      // this.pos = p5.createVector(130, 130)
      // velocity
      // this.vel = p5.createVector(p5.random(-vel, vel), p5.random(-vel, vel))
      // this.vel = p5.createVector(-vel, vel)
      this.vel = p5.createVector(((Math.random() * 3) + -1), ((Math.random() * 3) + -1))
      // Size
      // this.size = Math.round(p5.random(1,8))
      this.size = 5
      // this.size = p5.random(1,5);
      this.direction = 'up'
      this.color = colors[Math.floor(Math.random() * (colors.length))]
    }

    // updates movement by adding velocity
    update() {

      this.pos.add(this.vel)

      // console.log(this.vel)
      let size = this.size,
        direction = this.direction,
        speed = Math.sqrt((this.vel.x * this.vel.x) + (this.vel.y * this.vel.y))

      // console.log(speed)

      // bouncy shit 
      // if (direction === 'down') {
      //   // console.log('down')
      //   size -= .1 * speed
      //   // console.log(size)
      //   if (size <= 1) {
      //     direction = 'up'
      //   }
      // } else {
      //   // console.log('up')
      //   size += .05 * speed
      //   // console.log(size)
      //   if (size >= 8) {
      //     // console.log('here')
      //     direction = 'down'
      //     size -= .05 * speed
      //   }
      // }

      this.size = size
      this.direction = direction
      // this.color = colors[Math.floor(Math.random() * (colors.length))]

      this.edges()

    }

    // draw a single particle
    draw(p5, dark) {
      p5.noStroke()


      if (dark) {
        // p5.fill("rgba(0,0,0,.5)")
        // p5.fill(this.color)
        // p5.fill("rgba(51,255,51,.5)")
        p5.fill(this.color)
      } else {
        // p5.fill("rgba(51,255,51,.5)")
        // p5.fill("rgba(41,41,41,.75)")
        p5.fill(this.color)
      }
      p5.circle(this.pos.x, this.pos.y, this.size)
    }

    // detect edges
    edges() {

      // check if particle is hitting the edge of circle, calculates distance from center and compares to radius
      let fromC = Math.sqrt(Math.pow((this.pos.y - 130), 2) + Math.pow((this.pos.x - 130), 2))

      // if (fromC >= 124) {
      // console.log(fromC)

      // this just goes back and forth
      // this.vel.x *= -1
      // this.vel.y *= -1

      // this works but is square
      // if (this.pos.x < 6 || this.pos.x >= 254) {
      //   this.vel.x *= -1
      // }

      // if (this.pos.y < 6 || this.pos.y >= 254) {
      //   this.vel.y *= -1
      // }


      //idk trying to make this work
      // if (this.pos.x - 124 < 0 || this.pos.x - 124 >= 124) {
      //   this.vel.x *= -1
      // }

      // if (this.pos.y - 124 < 0 || this.pos.y - 124 >= 124) {
      //   this.vel.y *= -1
      // }
      // }

      // idk whats going on here haha

      const width = window.innerWidth;
      if (this.pos.x < 2 || this.pos.x > width - 2) {
        navigator.vibrate(10)
        this.vel.x *= -1
      }

      const height = window.innerHeight;
      if (this.pos.y < 4 || this.pos.y > height - 2) {
        navigator.vibrate(10)
        this.vel.y *= -1
      }

      // // const width = window.innerWidth
      // if (this.pos.x < 0 || this.pos.x > fromC) {
      //   this.vel.x *= -1
      // }

      // // const height = window.innerHeight
      // if (this.pos.y < 0 || this.pos.y > fromC) {
      //   this.vel.y *= -1
      // }





    }


    // Connect particles
    checkParticles(particles, p5, dark) {
      particles.forEach(particle => {
        const d = p5.dist(
          this.pos.x,
          this.pos.y,
          particle.pos.x,
          particle.pos.y
        )

        if (d < 48) {
          if (!dark) {
            p5.stroke("rgba(0,0,0,0.35)")
          } else {
            // p5.stroke("rgba(51,255,51,.35)")
            // p5.stroke("rgba(255,255,255,.35)")
            p5.stroke("rgba(0,100,0,.5)")
          }
          p5.line(this.pos.x, this.pos.y, particle.pos.x, particle.pos.y)
        } else if (d < 98) {
          if (!dark) {
            p5.stroke("rgba(0,0,0,0.15)")
          } else {
            // p5.stroke("rgba(51,255,51,.15)")
            // p5.stroke("rgba(255,255,255,.15)")
            p5.stroke("rgba(0,100,0,.25)")
          }
          p5.line(this.pos.x, this.pos.y, particle.pos.x, particle.pos.y)
        } else if (d < 148) {
          if (!dark) {
            p5.stroke("rgba(0,0,0,0.05)")
          } else {
            // p5.stroke("rgba(51,255,51,.05)")
            // p5.stroke("rgba(255,255,255,.05)")
            p5.stroke("rgba(0,100,0,.15)")
          }
          p5.line(this.pos.x, this.pos.y, particle.pos.x, particle.pos.y)
        }
      })


    }
  }

  if (typeof window !== `undefined`) {
    return (
      <>
        <Sketch setup={setup} draw={draw} />
      </>
    )
  }

  return <div></div>
}

export default Particles
