import React, { useEffect, useState, useContext } from "react"
import { Link, navigate } from "gatsby"
import axios from "axios"
import SEO from "../components/seo"
// import Login from "../components/login"
import indexStyles from "../styles/index.module.css"
import headerStyles from "../styles/header.module.css"
import { Helmet } from "react-helmet"
// import wordsFile from "./projects/words.json"
import SettingsContext from "../context/SettingsContext"
import ModeContext from "../context/ModeContext"
import { getCurrentSettings } from "../services/settings"
import Particles from "../components/particles"
import Footer from "../components/footer"
import Loader from "../components/loader"
import weatherService from "../services/weather"

const IndexPage = () => {
  const { settings, getSettings } = useContext(SettingsContext)
  const { dark, toggleDark } = useContext(ModeContext)
  // const dark = false
  const [words, setWords] = useState({})
  const [taps, setTaps] = useState(0)
  const [currentWord, setCurrentWord] = useState("")
  // const [beenThroughHere, setBeenThroughHere] = useState(false)
  const [liveGames, setLiveGames] = useState(false)
  const [game, setGame] = useState(false)
  const [recordSpinning, setRecordSpinning] = useState(false)
  const [weather, setWeather] = useState("")
  const [excerpts, setExcerpts] = useState(["IMG_0762.png"])
  const [showImages, setShowImages] = useState(false)
  const [record, setRecord] = useState(
    {title: 'Loss Of Life', 
     artist: 'MGMT', 
     url: 'https://music.youtube.com/playlist?list=OLAK5uy_nzSEMEpaSvvKkJIPDrBtQU4xkgPRWWzM4&si=nX6QRN4J3xTMimee'})
  const [showRecord, setShowRecord] = useState(false)
  const [current, setCurrent] = useState(0)
  const [cover, setCover] = useState(true)
  const [feedback, setFeedback] = useState('')

  const getAndSetWeather = async () => {
    const weatherData = await weatherService.getWeather()
    setWeather(weatherData)
  }

  // const photoURLArr = settings.paylod ? settings.payload.split('/') : []
  // console.log(photoURLArr)

  useEffect(() => {

    // updateCurrentWord()

    // window.location.replace("https://www.google.com/search?q=yourself")

    const checkYoself = async () => {

      // checkIfLiveGames()
      checkIfCubsPlaying()
      updateWords()
      console.log("I give up.")
      console.log("For now.")
      


      const loggedInUser = window.localStorage.getItem("loggedInUser")
      // if (!loggedInUser) {window.location.replace("https://www.google.com/")}

      getAndSetWeather()

      if (loggedInUser) {
        const loggedInUserJSON = JSON.parse(loggedInUser)
        const isValidUser = await axios.post('https://api.sup.cool/users/auth', { token: loggedInUserJSON.token })

        if (isValidUser.request.status === 200) {
          // navigate('/space')
        }
      }
    }

    checkYoself()




  }, [])

  const updateWords = async () => {
    const currentSettings = await getCurrentSettings()
    const settingsData = currentSettings.data.settings
    // console.log(settingsData)

    const dateObj = new Date(settingsData.updated);
    let options = { hourCycle: 'h23', hour: '2-digit', minute: '2-digit', weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }
    let dateUpdated = dateObj.toLocaleDateString("en-us", options).split(',').join('');

    setWords({
      show: settingsData.showCTA,
      words: settingsData.cta,
      url: settingsData.payload,
      updated: dateUpdated
    })

    setTimeout(() => {
      updateWords()
    }, 10000)
  }

  // const updateCurrentWord = async () => {

  //   const randNum = (max) => { return Math.floor(Math.random() * max) }
  //   const randWord = randNum(wordsFile.length - 7)
  //   // setCurrentWord(`   ${wordsFile[randWord]}
  //   // ${wordsFile[randWord + 1]} ${wordsFile[randWord + 2]}
  //   // ${wordsFile[randWord + 3]} sup ${wordsFile[randWord + 4]}
  //   // ${wordsFile[randWord + 5]} ${wordsFile[randWord + 6]}
  //   // ${wordsFile[randWord + 7]}`)
  //   setCurrentWord(`${wordsFile[randWord]}.`)

  //   setTimeout(() => {
  //     // getAndSetScore()
  //     updateCurrentWord()
  //   }, 1490)
  // }

  const checkIfLiveGames = async () => {
    const mlbScheduleUrl = 'https://statsapi.mlb.com/api/v1/schedule?sportId=1'

    const scheduleData = await axios.get(mlbScheduleUrl)
    const gamesArr = scheduleData.data.dates[0].games

    let gamesLive;

    gamesArr.forEach((game) => {
      if (game.status.abstractGameState === "Live") {
        gamesLive = true
      }
    })

    // console.log(gamesLive)

    setLiveGames(gamesLive)

  }

  const checkIfCubsPlaying = async () => {
    const mlbScheduleUrl = 'https://statsapi.mlb.com/api/v1/schedule?sportId=1'

    const scheduleData = await axios.get(mlbScheduleUrl)
    const gamesArr = scheduleData.data.dates[0].games
    // console.log(gamesArr)

    let gameID = false;
    let gameStatus = false;

    gamesArr.forEach(game => {
      if (game.teams.away.team.name === 'Chicago Cubs' || game.teams.home.team.name === 'Chicago Cubs') {
        // console.log(game.gamePk)
        gameID = game.gamePk
        gameStatus = game.status.detailedState


        if (gameStatus === 'In Progress') {
          // if (gameStatus === 'Final') {
          setGame(game)
          console.log(game)

          setTimeout(() => {
            checkIfCubsPlaying()

          }, 300000)
        } else {
          setGame(false)
        }
      }
    })

    // console.log(gameStatus)


    // console.log(gameID)

  }

  const spinRecord = () => {
    if (liveGames) {
      setRecordSpinning(!recordSpinning)
    }
  }

  const decrementCurrent = (e) => {
    e.preventDefault();
    if (current > 0) {
      setCurrent(current - 1)
    }
    else {
      setCurrent(excerpts.length - 1)
    }
  }

  const increaseCurrent = (e) => {
    e.preventDefault();
    if (current <= excerpts.length - 2) {
      setCurrent(current + 1)
    }
    else {
      setCurrent(0)
    }
  }

  const submitFeedback = async () => {

    if (feedback != '') {

      
      const res = await axios.post('https://api.sup.cool/feedback', {feedback})
      
      console.log(res.data)
      setFeedback('')
      // console.log('submit feedback')
    }
  }

  return (
    <div className={dark ? "dark" : "light"} style={{ overflow: 'hidden', cursor: 'default' }}>

      <Helmet>
        <script
          src="https://kit.fontawesome.com/e4bfb220a1.js"
          crossorigin="anonymous"
        ></script>

      </Helmet>

      <SEO title="home" />

      <Link to="/" className={headerStyles.logo} style={{ zIndex: '100' }}>
        {/* <img src={dark ? "../sup_logo_word_tb_white.gif" : "../sup_logo_word_tb.gif"} alt="sup." /> */}
        <img style={{transform: 'translateY(-12px)'}} src={dark ? "sup_tb_w.png" : "sup_tb.png"} alt="sup." />
      </Link>
      {/* <Link to="/" className={headerStyles.logo} style={{ zIndex: '100' }}>
        <img src="../sup_logo_word_tb_white.gif" alt="sup." />
      </Link> */}
      {/* {settings.showDots &&
        <>
          <img className={headerStyles.dot} src="../sup_logo_dot_tb.gif" alt="sup." style={{ animationDelay: '1s' }} />
        </>} */}

      {/* <button className={dark ? `penDark` : `pen`} onClick={() => {
        navigate('/gate')
      }}>
        <i className="fa fa-user-astronaut" style={{ transform: 'translateX(-2px)' }}></i>

      </button> */}

      {/* <button
        className={dark ? `toggleDark` : `toggle`}
        onClick={() => toggleDark()}
      >
        <i className="far fa-lightbulb"></i>
      </button> */}

      <button
        className={dark ? `toggleDark` : `toggle`}
        onClick={() => toggleDark()}
      >
        <img src={dark ? "power_g.png" : "power.png"} style={{transform: 'translateY(4px)'}}></img>
      </button>


      {words.show &&

        <div className='fadeIn' style={{ position: ' absolute', width: '78%', left: '50%', transform: 'translateX(-50%)', bottom: '50%', zIndex: '50', fontSize: '12px', textAlign: 'center', cursor: 'default' }}>


          <p style={{ color: dark ? 'rgba(255,255,255, .5)' : 'rgba(41,41,41, .5)', fontSize: '12px', textAlign: 'center', cursor: 'default', marginBottom: '4px' }}>{words.updated}</p>

          {words.url !== "https://sup.cool" ?
            <>
              <a href={words.url} target='_blank' rel="noreferrer" style={{ color: 'rgba(30, 139, 195, .75)', fontSize: '16px', textAlign: 'center', cursor: 'pointer' }}>{words.words}</a>
            </>
            :
            <>
              <p style={{ color: dark ? 'rgba(255,255,255, .75)' : 'rgba(41, 41, 41, .75)', fontSize: '16px', textAlign: 'center', cursor: 'pointer' }}>{words.words}</p>
            </>
          }

        </div>
      }




      {/* <img className={liveGames ? "spin" : ""} draggable='true' src="./mlb_sticker.png" alt="mlb sticker" style={{ display: 'block', width: '50px', height: 'auto', position: 'absolute', boxSizing: 'border-box', right: '12%', transform: 'translateX(-50%)', bottom: '6px', zIndex: '100', cursor: 'pointer', WebkitTapHighlightColor: 'transparent' }} onClick={() => { navigate('/baseball') }} /> */}

      {showRecord &&
        <>

        <a href={record.url} target="_blank" rel="noreferrer">
          <img className="spin" src="./great_record.png" alt="great record" style={{ display: 'block', width: '75px', height: 'auto', position: 'absolute', boxSizing: 'border-box', left: '10%', top: '95px', zIndex: '100', cursor: 'pointer', WebkitTapHighlightColor: 'transparent' }} onClick={() => { navigate('/') }} />

          <p style={{ display: 'block', position: 'absolute', boxSizing: 'border-box', left: '14%', top: '60px', zIndex: '100', cursor: 'pointer', WebkitTapHighlightColor: 'transparent', textDecoration: 'none', color: dark ? 'rgba(255,255,255,.5)' : 'rgba(41,41,41,.5)', fontSize: '14px', textAlign: 'center' }}>{record.title}</p>
          <p style={{ display: 'block', position: 'absolute', boxSizing: 'border-box', left: '14%', top: '73px', zIndex: '100', cursor: 'pointer', WebkitTapHighlightColor: 'transparent', textDecoration: 'none', color: dark ? 'rgba(255,255,255,.35)' : 'rgba(41,41,41,.35)', fontSize: '12px', textAlign: 'center' }}>{record.artist}</p>
        </a>

        </>
      }

      {/* controlslist='nodownload' */}

      {/* <audio controls loop style={{ position: ' absolute', transform: 'translate(-50%, 50%)', left: '50%', top: '42%', zIndex: '54', opacity: '.5' }} onPlay={() => { spinRecord() }} onPause={() => { spinRecord() }}>
        <source src="/hourglass_demo.wav" type="audio/ogg" />
        Your browser does not support the audio element.
      </audio> */}

      {/* <Link to="/baseball" style={{ textAlign: 'center', position: 'absolute', top: '46%', left: '50%', transform: 'translateX(-50%)', zIndex: '50' }}>i guess this still works</Link> */}

      {/* <a href="https://www.theverge.com/c/24070570/internet-cables-undersea-deep-repair-ships" style={{ textAlign: 'left', position: 'absolute', top: '7%', left: '50%', width: '85%', transform: 'translateX(-50%)', zIndex: '50' }}>interesting article about the underlying infrastructure of the internet [the verge]</a> */}

      {
        game ?

          <>
            <Link to='/baseball/scoreboard' state={{ gameId: game.gamePk }} style={{ position: 'absolute', top: '12%', right: '25px', width: '200px', height: '42px', borderRadius: '42px', backgroundColor: 'none', zIndex: '100' }}>

              <img src={`./mlb_logos/${game.teams.home.team.name.toLowerCase().split(' ').join('_')}_logo.png`} alt={`${game.teams.home.team.name} logo`} style={{ display: 'inline-block', width: '42px', height: '42px', objectFit: 'scale-down', margin: 'auto', zIndex: '51', marginRight: '100px' }} />
              <img src={`./mlb_logos/${game.teams.away.team.name.toLowerCase().split(' ').join('_')}_logo.png`} alt={`${game.teams.away.team.name} logo`} style={{ display: 'inline-block', width: '42px', height: '42px', objectFit: 'scale-down', margin: 'auto', zIndex: '51' }} />

              <p style={{ position: 'absolute', left: '62px', top: '10px', color: dark ? 'rgba(255,255,255,.86)' : 'rgba(41,41,41,.86)' }}>{game.teams.home.score}</p>
              <p style={{ position: 'absolute', left: '91px', top: '8px', color: dark ? 'rgba(255,255,255,.86)' : 'rgba(41,41,41,.86)' }}>{'-'}</p>
              <p style={{ position: 'absolute', right: '76px', top: '10px', color: dark ? 'rgba(255,255,255,.86)' : 'rgba(41,41,41,.86)' }}>{game.teams.away.score}</p>

            </Link>
          </>
          :
          <></>
      }

      {/* TRACK IMG AND AUDIO */}

      {/* <p className = "breathe" style={{
        position: 'relative', textAlign: 'center', fontSize: '16px', marginTop: '42vh', marginLeft: '0px', opacity: '.75', color: dark ? 'rgba(255,255,255,1)' : 'rgba(41, 41, 41, 1)', zIndex: '20', cursor: 'text', transform: 'scaleY(1)'
      }}>work <br />in <br />progress</p> */}


      {/* <textarea placeholder="open to feedback..." value={feedback} name="feedback" style={{position:'absolute', height: '445px', width:'82%', maxWidth: '350px', left: '50%', top: '44%', transform:'translate(-50%,-50%)', border: '1px solid rgba(41,41,41, .75)', resize:'none', fontFamily:'sans-serif', color:'rgba(41,41,41,.75)', fontSize:'16px'}} onChange={(e) => {
        setFeedback(e.target.value);
        // console.log(feedback)
      }}></textarea>

      <button style={{position:'absolute', height: '45px', width:'82%', maxWidth: '350px', left: '50%', top: '80%', transform:'translate(-50%,-50%)', backgroundColor: feedback != '' ? 'rgba(51, 255, 51, 0.1)' : 'rgb(255,255,255)', border: '1px solid rgba(41,41,41, .75)', boxSizing:'border-box', transitionProperty:'background-color', transitionDuration:'1s', color: 'rgba(41,41,41, .75)'}} onClick={() => {
        
        submitFeedback()
      }}>______</button> */}
      
      

      {/* youtube vid */}
      

      {/* <iframe style={{ position: 'absolute', zIndex: '150', top: '48%', left: '50%', transform: 'translate(-50%, -50%)' }} width="350" height="525" src="https://www.youtube.com/embed/kRh6598RmHM?si=hJq5rZiIX9BWshWQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen ></iframe> */}
      

      {/* <div style={{
        position: 'absolute', width: '350px', height: '525px', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: '51',
        transitionProperty: 'opacity',
        opacity: '1',
        backgroundColor: 'black'
      }} ></div> */}



      {/* < img src="./IMG_0006.JPG" alt="an image" style={{
        position: 'absolute', width: '350px', height: 'auto', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: '20',
        transitionProperty: 'opacity',
        opacity: '1'
      }} /> */}

      {/* < img src="./minute.png" alt="an image" style={{
        position: 'absolute', width: '350px', height: 'auto', left: '49%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: '20',
        transitionProperty: 'opacity',
        opacity: '1'
      }} /> */}


      {/* <Loader></Loader> */}


      {/* image counter dots */}
      {/* {excerpts.map((img, index) => {
          return (
            <div style={{ display: 'inline-block', width: '8px', height: '8px', borderRadius: '8px', backgroundColor: dark ? 'rgba(255,255,255,1)' : 'rgba(41,41,41,1)', marginRight: '10px', opacity: current === index ? '.75' : '.25' }}></div>
          )
        })} */}


      {/* start image slideshow */}


      {showImages && 
      
      <>

      <img src={`./${excerpts[current]}`} alt="an image" style={{
        position: 'absolute', width: '65%', minWidth: '375px', maxWidth: '850px', height: 'auto', left: '50%', top: '48%', transform: 'translate(-50%, -50%)', zIndex: '20',
        transitionProperty: 'opacity',
        opacity: '1'
      }} />
      

      <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', bottom: '10%', width: '85%', maxWidth: '350px', height: '50px', textAlign: 'center' }}>

        

        <p style={{fontFamily: 'sans-serif', fontSize: '15px', color: dark ? 'rgba(255,255,255,.5)' : 'rgba(41,41,41,.5)', letterSpacing: '2px', transform: 'translateY(6px)'}}>{current + 1}/{excerpts.length}</p>

        <div style={{ color: dark ? 'rgba(255,255,255,.75)' : 'rgba(41,41,41,.75)', position: 'absolute', top: '0', left: '10%', fontSize: '20px', zIndex: '100', marginTop: '0px', width: '40px', height: '40px', cursor: 'pointer' }} onClick={(e) => {
          decrementCurrent(e)
          navigator.vibrate(32)
        }}>


          <img src={dark ? "less_than_w.png" : "less_than.png"} alt="less than symbol" style={{ color: dark ? 'rgba(255,255,255,.36)' : 'rgba(41,41,41,.36)', position: 'absolute', left: '10%', fontSize: '24px', zIndex: '99', marginTop: '0px' }}/>
          {/* <i className="fas fa-chevron-circle-left" style={{ color: dark ? 'rgba(255,255,255,.36)' : 'rgba(41,41,41,.36)', position: 'absolute', left: '10%', fontSize: '24px', zIndex: '99', marginTop: '0px' }}
          ></i> */}

        </div>

        <div style={{ color: dark ? 'rgba(255,255,255,.75)' : 'rgba(41,41,41,.75)', position: 'absolute', top: '0', right: '10%', fontSize: '20px', zIndex: '100', marginTop: '0px', width: '40px', height: '40px', cursor: 'pointer' }} onClick={(e) => {
          increaseCurrent(e)
          navigator.vibrate(32)
        }}>


          <img src={dark ? "greater_than_w.png" : "greater_than.png"} alt="greater than symbol" style={{ color: dark ? 'rgba(255,255,255,.36)' : 'rgba(41,41,41,.36)', position: 'absolute', right: '10%', fontSize: '24px', zIndex: '99', marginTop: '0px' }}/>
          {/* <i className="fas fa-chevron-circle-right" style={{ color: dark ? 'rgba(255,255,255,.36)' : 'rgba(41,41,41,.36)', position: 'absolute', right: '10%', fontSize: '24px', zIndex: '99', marginTop: '0px' }}
          ></i> */}

        </div>
      </div>

      </>

      }


      {/* end image slideshow */}



      {/* <div style={{
        position: 'absolute', width: '126px', height: '55px', borderRadius: '55px', left: '50%', top: '47%', transform: 'translate(-50%, -50%)', zIndex: '200',
        transitionProperty: 'opacity',
        opacity: '.98',
        backgroundColor: 'white'
      }} ></div> */}


      {/* END TRACK IMG AND AUDIO */}

      {/* < div className="migraine" style={{
        position: 'absolute',
        display: 'block',
        width: '100%',
        height: '100%',
      }}
        onClick={() => {
          setTaps(taps >= 2 ? 0 : taps + 1)
        }
        }
      >

        <p style={{
          position: 'absolute', color: 'rgba(41, 41, 41, .75)', fontSize: '12px', marginLeft: '12px', bottom: '10px', opacity: '.5', transform: 'scaleY(1)', zIndex: '100'
        }} ><span className='blink'>|</span></p>


      </div > */}

            {/* <audio controls loop style={{ position: ' absolute', transform: 'translate(-50%, 50%)', left: '50%', top: '56%', zIndex: '54', opacity: '.26' }}>
                <source src="/quite_content.mp3" type="audio/ogg" />
                Your browser does not support the audio element.
            </audio>


            {cover ?
                <>
                    < img src="./quite_content_.png" alt="an image" style={{
                        position: 'absolute', width: '350px', height: 'auto', left: '50%', top: '48%', transform: 'translate(-50%, -50%)', zIndex: '20',
                        transitionProperty: 'opacity',
                        opacity: '1'
                    }} />
                </>
                :
                <>

                    < img src="./quite_content_tracklist.png" alt="an image" style={{
                        position: 'absolute', width: '350px', height: 'auto', left: '50%', top: '48%', transform: 'translate(-50%, -50%)', zIndex: '20',
                        transitionProperty: 'opacity',
                        opacity: '1'
                    }} />
                </>
            }

            <div style={{ position: 'absolute', bottom: '11vh', left: '50%', transform: 'translateX(-50%)', width: '50px', height: '50px', display: 'block', margin: 'auto', textAlign: 'center' }} onClick={() => { setCover(!cover) }}>
                <i className="fas fa-sync-alt" style={{ fontSize: '22px', color: dark ? 'rgba(255,255,255, .75)' : 'rgba(41, 41, 41, .75)' }}></i>
            </div> */}

      <Footer></Footer>
    </div >
  )
}

export default IndexPage
